import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../../Contexts';
import './FiltersAndSortingMobile.scss';
import { faX, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface IFilterOption {
    option: string;
    selected: boolean;
}

export interface IFilter {
    category: string;
    options: IFilterOption[];
    isSort: boolean;
}

export const FiltersAndSorting = () => {
    const {filters, setFilters} = useContext(FilterContext);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    useEffect(() => {
        setFilters([
            {
                category: 'Sellers',
                options: [{option: 'Amazon', selected: false}, {option: 'Best Buy', selected: false}, {option: 'New Egg', selected: false}],
                isSort: false
            },
            {
                category: 'Brands',
                options: [{option: 'Nvidia', selected: false}, {option: 'Radeon', selected: false}],
                isSort: false
            },
            {
                category: 'Sort',
                options: [{option: 'Rating', selected: true}, {option: 'Price (Low to High)', selected: false}, {option: 'Price (High to Low)', selected: false}],
                isSort: true
            },
        ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFilterOpen) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }    
    }, [isFilterOpen])

    const optionSelectedClicked = (option: IFilterOption) => {
        const filtersClone = filters.slice();
        for (let filter of filtersClone) {
            if (filter.options.includes(option)) {
                filter.options[filter.options.indexOf(option)] = {option: option.option, selected: !option.selected}
            }
        }
        setFilters(filtersClone);
    }

    const optionCircleSelectedClicked = (option: IFilterOption) => {
        const filtersClone = filters.slice();
        for (let filter of filtersClone) {
            if (filter.options.includes(option)) {
                for (let optionVar of filter.options) {
                    if (optionVar === option) {
                        option.selected = true;
                    } else {
                        optionVar.selected = false;
                    }
                }
            }
        }
        setFilters(filtersClone);
    } 

    return(
        <div className='filters-container-mobile'>
            <div className='filters-header-container-mobile'>
                <div className='results-header-mobile'>Results</div>
                <div 
                    id='filter-button-container-mobile'
                    className='filter-button-container-mobile' 
                    onClick={() => setIsFilterOpen(isFilterOpen => !isFilterOpen)}
                    style={{color: isFilterOpen ? '#00C405' : 'white'}}>
                    Filter
                    <FontAwesomeIcon icon={isFilterOpen ? faAngleUp : faAngleDown} style={{marginLeft: 5}}/>
                </div>
            </div>
            {isFilterOpen && 
            <>
            <div className='filter-backdrop-mobile' onClick={() => setIsFilterOpen(false)}/>
            <div id='filters-popup-container-mobile' className='filters-popup-container-mobile'>
                <div className='filter-popup-header-mobile'>
                    <div className='filter-popup-header-title'>Filters</div>
                    <div className='filter-popup-close-button-mobile' onClick={() => setIsFilterOpen(false)}>
                        Close
                        <FontAwesomeIcon icon={faX} style={{marginLeft: 8}}/>
                    </div>
                </div>
                <div className='filter-categories-container-mobile'>
                {filters !== undefined && filters.map((filter: IFilter, index: number) => (
                <div className='filter-category-container-mobile' key={index + 1}>
                    <div className='filter-category-title-mobile'>{filter.category}</div>
                    <div className='filter-options-category-mobile'>
                        {filter.options.map((option: IFilterOption, index: number) => (
                            <div className='filter-option-category-mobile' key={index + 1} 
                            style={{color: option.selected ? '#00C405' : 'white'}}
                            onClick={() => {
                                if (filter.isSort) {
                                    optionCircleSelectedClicked(option);
                                } else {
                                    optionSelectedClicked(option);
                                }
                            }}>
                                {option.option}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
                </div>
            </div>
            </>}
        </div>
    );
}