import './SearchMobile.scss';
import { useContext, useState } from 'react';
import { SearchContext } from '../../../Contexts';

export const SearchMobile = () => {
    const {searchText, setSearchText} = useContext(SearchContext);

    return(
        <div className='search-container-mobile'>
            <input className='search-bar-mobile' onChange={(e) => setSearchText(e.target.value)}/>
        </div>
    );
}