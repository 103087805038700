import { useEffect, useState } from 'react';
import { SearchContext } from '../Contexts';
import { DesktopView } from './DesktopView/DesktopView';
import './HomePage.scss';
import { MobileView } from './MobileView/MobileView';

export const HomePage = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const deviceAgent = navigator.userAgent.toLowerCase();
        const agentId = deviceAgent.match(/(iphone|ipad|ipod)/);
        if (agentId || window.innerWidth <= 1024) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [])

    return(
        <div style={{backgroundColor: '#101010'}}>
            <SearchContext.Provider value={{searchText, setSearchText}}>
                {isMobile ?
                <MobileView /> :
                <DesktopView />}
            </SearchContext.Provider>
        </div>
    );
}