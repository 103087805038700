import { useContext, useEffect, useState } from 'react';
import { FilterContext, SearchContext } from '../../../../Contexts';
import './SearchResultsMobile.scss';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rating from '@mui/material/Rating';

interface IVideoCard  {
    Id: number;
    Title: string;
    Url: string;
    Image: string;
    Seller: string;
    Price: number;
    Created: Date;
    Brand: string;
    Rating: string;
  }

export const SearchResults = () => {
    const [videoCards, setVideoCards] = useState<IVideoCard[]>([]);
    const [sortedVideoCards, setSortedVideoCards] = useState<IVideoCard[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [amountToShow, setAmountToShow] = useState<number>(10);
    const {searchText, setSearchText} = useContext(SearchContext);
    const {filters, setFilters} = useContext(FilterContext);

    useEffect(() => {
        fetch('https://findvideocards.com/GetVideoCards.php').then((response) => {
            response.text().then((data) => {
                const formattedData = JSON.parse(data);
                const finalCards: IVideoCard[] = [];
                for (const card of formattedData) {
                    finalCards.push({
                        Id: card.Id,
                        Title: card.Title,
                        Url: card.Url,
                        Image: card.Image,
                        Seller: card.Seller,
                        Price: card.Price.slice().replaceAll('$', '').replaceAll(',', '').replaceAll('.', ''),
                        Created: card.Created,
                        Brand: card.Brand,
                        Rating: card.Rating.length === 1 ? card.Rating + '.0' : card.Rating,
                    })
                }
                setIsLoading(false);
                setVideoCards(finalCards);
                setSortedVideoCards(finalCards);
            })
        });
    }, []);

    useEffect(() => {
        if (filters != null) {
            let cardsFiltered = videoCards.slice().filter(x =>
                x.Title.toLowerCase().includes(searchText.toLowerCase()) &&
                isSellerInFilter(x.Seller) &&
                isBrandInFilter(x.Brand)
                )
            sortCards(cardsFiltered);
            setSortedVideoCards(cardsFiltered);
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, filters, isLoading]);

    const sortCards = (cardsFiltered: IVideoCard[]) => {
        let filterIdx = 0;
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].category === 'Sort') filterIdx = i;
        }
        let selectedOption = "";
        for (const option of filters[filterIdx].options) {
            if (option.selected) selectedOption = option.option;
        }

        if (selectedOption === 'Rating') {
            cardsFiltered.sort((a, b) => {
                if (a.Rating === 'none' && b.Rating === 'none') return 0 - 0;
                if (a.Rating === 'none') return parseFloat(b.Rating) - 0;
                if (b.Rating === 'none') return 0 - parseFloat(a.Rating);
                return parseFloat(b.Rating) - parseFloat(a.Rating)
            });
        }
        if (selectedOption === 'Price (Low to High)') {
            cardsFiltered.sort((a, b) => (a.Price - b.Price));
        }
        if (selectedOption === 'Price (High to Low)') {
            cardsFiltered.sort((a, b) => (b.Price - a.Price));
        }
        return cardsFiltered;
    }

    const isSellerInFilter = (seller: string) => {
        let falseCount = 0;
        for (const option of filters[0].options) {
            if (!option.selected) falseCount++;
            if (option.option === seller) {
                if (option.selected) return true;
            }
        }
        if (falseCount >= filters[0].options.length) return true;

        return false;
    }

    const isBrandInFilter = (brand: string) => {
        let falseCount = 0;
        for (const option of filters[1].options) {
            if (!option.selected) falseCount++;
            if (option.option === brand) {
                if (option.selected) return true;
            }
        }
        if (falseCount >= filters[1].options.length) return true;

        return false;
    }

    return(
        <div className='search-results-container-mobile'>
            {
                isLoading ? <FontAwesomeIcon className='results-loading-spinner-mobile' icon={faSpinner} spin={true} /> :
                <>
                    {sortedVideoCards.slice(0, amountToShow).map((videoCard: IVideoCard, index: number) => (
                    <div className='card-result-container-mobile' key={index + 1}>
                        <a className='card-image-mobile-a' href={videoCard.Url} target="_blank">
                            <img className='card-image-mobile' alt='Preview not available' src={videoCard.Image}/>
                        </a>
                        <div className='card-info-mobile'>
                            <a className='card-title-mobile' href={videoCard.Url} target="_blank">
                                {videoCard.Title}
                            </a>
                            <div className='card-price-and-seller-mobile'>
                                <h2 className='card-price-mobile'>
                                    <>
                                    {'$' + videoCard.Price.toString().slice(0, videoCard.Price.toString().length - 2) + '.' +  
                                    videoCard.Price.toString().slice(videoCard.Price.toString().length - 2)}
                                    </>
                                </h2>
                                <div className='card-rating-mobile'>
                                {videoCard.Rating !== 'none' ? 
                                <>
                                    <Rating value={parseFloat(videoCard.Rating)} readOnly precision={.1} sx={{fontSize: '5vw',}}/>
                                    <div className='rating-text-mobile'>{videoCard.Rating}</div>
                                </>
                                 : <></>}
                            </div>
                                <div className='card-seller-mobile'>
                                    {videoCard.Seller}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {sortedVideoCards.length >= amountToShow ? 
                <button className='show-more-button-mobile' onClick={() => {setAmountToShow(amountToShow => amountToShow * 2)}}>
                    
                    Show More Results
                </button> : <></>}
            </>
            }
            
            
        </div>
    );
}