import { Header } from '../../Components/Header/HeaderMobile/HeaderMobile';
import { SearchMobile } from '../../Components/Search/SearchMobile/SearchMobile';
import { SearchContent } from '../../Components/SearchContent/SearchContentMobile/SearchContentMobile';
import './MobileView.scss';

export const MobileView = () => {
    
    return(
        <div className='mobile-view-container'>
            <Header />
            <SearchMobile />
            <SearchContent />
        </div>
    );
}