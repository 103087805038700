import { HomePage } from "./Pages/HomePage";

function App() {
  return (
    <div id='app-root' style={{backgroundColor: '#101010', height: '100vh', overflowY: 'auto'}}>
      <HomePage />
    </div>
  );
}

export default App;
