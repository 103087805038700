import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../../Contexts';
import './FiltersAndSorting.scss';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface IFilterOption {
    option: string;
    selected: boolean;
}

export interface IFilter {
    category: string;
    options: IFilterOption[];
    isSort: boolean;
}

export const FiltersAndSorting = () => {
    const {filters, setFilters} = useContext(FilterContext);

    useEffect(() => {
        setFilters([
            {
                category: 'Sellers',
                options: [{option: 'Amazon', selected: false}, {option: 'Best Buy', selected: false}, {option: 'New Egg', selected: false}],
                isSort: false
            },
            {
                category: 'Brands',
                options: [{option: 'Nvidia', selected: false}, {option: 'Radeon', selected: false}],
                isSort: false
            },
            {
                category: 'Sort',
                options: [{option: 'Rating', selected: true}, {option: 'Price (Low to High)', selected: false}, {option: 'Price (High to Low)', selected: false}],
                isSort: true
            },
        ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const optionSelectedClicked = (option: IFilterOption) => {
        const filtersClone = filters.slice();
        for (let filter of filtersClone) {
            if (filter.options.includes(option)) {
                filter.options[filter.options.indexOf(option)] = {option: option.option, selected: !option.selected}
            }
        }
        setFilters(filtersClone);
    }

    const optionCircleSelectedClicked = (option: IFilterOption) => {
        const filtersClone = filters.slice();
        for (let filter of filtersClone) {
            if (filter.options.includes(option)) {
                for (let optionVar of filter.options) {
                    // console.log(option, option)
                    if (optionVar === option) {
                        option.selected = true;
                    } else {
                        optionVar.selected = false;
                    }
                }
            }
        }
        setFilters(filtersClone);
    }

    return(
        <div className='filters-container'>
            {filters !== undefined && filters.map((filter: IFilter, index: number) => (
                <div className='filter-category-container' key={index + 1}>
                    <div className='filter-category-title'>{filter.category}</div>
                    <div className='filter-options-category'>
                        {filter.options.map((option: IFilterOption, index: number) => (
                            <div className='filter-option-category' key={index + 1}>
                                {filter.isSort ? 
                                <div className='sort-circle-container' onClick={() => optionCircleSelectedClicked(option)}>
                                    <FontAwesomeIcon className='sort-circle-icon-outer' icon={faCircle} color='#2A2A2A'/> 
                                    <FontAwesomeIcon className='sort-circle-icon-inner' icon={faCircle} color={option.selected ? '#00C405' : '#2A2A2A'}/> 
                                </div> :
                                <div className='filter-checkbox-container' style={{backgroundColor: option.selected ? '#00C405' : '#2A2A2A'}}onClick={() => optionSelectedClicked(option)}>
                                    {option.selected ? <FontAwesomeIcon className='filter-checkbox-icon' icon={faCheck} /> 
                                    : <></>}
                                </div>}
                                <div className='filter-option' style={{marginLeft: filter.isSort ? '1vw' : 0}}>{option.option}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}