import { useState } from 'react';
import { FilterContext } from '../../../Contexts';
import { FiltersAndSorting, IFilter } from './FiltersMobile/FiltersAndSortingMobile';
import { RecommendedCards } from './RecommendedCardsMobile/RecommendedCardsMobile';
import './SearchContentMobile.scss';
import { SearchResults } from './SearchResultsMobile/SearchResultsMobile';

export const SearchContent = () => {
    const [filters, setFilters] = useState<IFilter[]>();
    
    return(
        <div className='search-content-container'>
            <FilterContext.Provider value={{filters, setFilters}}>
                <FiltersAndSorting />
                <SearchResults />
            </FilterContext.Provider>
        </div>
    );
}