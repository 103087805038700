import './SearchDesktop.scss';
import { useContext, useState } from 'react';
import { SearchContext } from '../../../Contexts';

export const SearchDesktop = () => {
    const {searchText, setSearchText} = useContext(SearchContext);

    return(
        <div className='search-container'>
            <input className='search-bar' onChange={(e) => setSearchText(e.target.value)}/>
        </div>
    );
}