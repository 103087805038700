import { Header } from '../../Components/Header/HeaderDesktop/HeaderDesktop';
import { SearchDesktop } from '../../Components/Search/SearchDesktop/SearchDesktop';
import { SearchContent } from '../../Components/SearchContent/SearchContentDesktop/SearchContentDesktop';
import './DesktopView.scss';

export const DesktopView = () => {
    
    return(
        <div className='desktop-view-container'>
            <Header />
            <SearchDesktop />
            <SearchContent />
        </div>
    );
}